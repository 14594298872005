import { defineMessages } from 'gatsby-theme-fast-ai-sidebar';

export default defineMessages({
	en: {
		defaultMessage: 'English',
		id: 'lang.en',
	},
	cs: {
		id: 'lang.cs',
		defaultMessage: 'Česky',
	},
});
