import { useEffect, useState } from 'react';

import useIsInViewport from './useIsInViewport';

export default function useJustIsInViewport(options) {
	const { inViewport, ref } =
		typeof document !== 'undefined'
			? // eslint-disable-next-line react-hooks/rules-of-hooks
			  useIsInViewport(options)
			: true;

	const [shown, setShown] = useState(inViewport);

	useEffect(() => {
		if (inViewport) {
			setShown(true);
		}
	}, [inViewport]);

	return { inViewport, shown: !!shown, ref };
}
