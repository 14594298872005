import React from 'react';
import PropTypes from 'prop-types';
import { Link as ComponentsLink } from '@fast-ai/ui-components';
import { NoIntlLink } from 'gatsby-theme-fast-ai-sidebar';

const ExternalLink = ({ to, ...rest }) => {
	const isExt = /^http/.exec(to);
	const Root = isExt ? ComponentsLink : NoIntlLink;
	const props = isExt ? { href: to } : { to };

	return <Root {...props} {...rest} />;
};
ExternalLink.propTypes = {
	to: PropTypes.string,
};

export default ExternalLink;
