/* eslint-disable max-len */
import React, { forwardRef } from 'react';

import SvgIcon from './Icon';

const KeyboardArrowRight = forwardRef(({ ...rest }, ref) => (
	<SvgIcon viewBox="0 0 24 24" {...rest} ref={ref}>
		<path d="M8.59 7.41L13.17 12L8.59 16.59L10 18L16 12L10 6L8.59 7.41Z" />
	</SvgIcon>
));

KeyboardArrowRight.displayName = 'KeyboardArrowRight';

export default KeyboardArrowRight;
