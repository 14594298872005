import React from 'react';

import { CalendlyButton } from './helpers';

const CALENDLY_USER = 'michal-krnak';
const CALENDLY_ZOE_REGISTRATION_EVENT = 'data_recommendations_dicovery_call';

const SalesCalendlyButton = ({ ...rest }) => (
	<CalendlyButton
		event={CALENDLY_ZOE_REGISTRATION_EVENT}
		user={CALENDLY_USER}
		{...rest}
	/>
);
export default SalesCalendlyButton;
