import React from 'react';
import { useIntlContext } from 'gatsby-theme-fast-ai-sidebar/src/intl';

import { Link as IntlLink } from '../intl';

export const genLocalizedTopicLink = ({ currentLanguage, topicData }) => {
	if (!topicData) {
		return;
	}
	const { alternates } = topicData;

	if (!currentLanguage || !topicData.slug || !topicData.name) {
		throw new Error(
			`Invalid topic data: ${JSON.stringify({ currentLanguage, topicData })}`
		);
	}

	const alternate =
		(alternates || []).find(x => x.lang === currentLanguage) || topicData;

	if (alternate) {
		const defaultLanguage = topicData.lang;
		return {
			to: `${
				!defaultLanguage || alternate.lang === defaultLanguage
					? ''
					: `/${alternate.lang}`
			}/blog/${alternate.slug}/`,
			slug: alternate.slug,
			name: alternate.name,
			description: alternate.description,
		};
	}
};

const BlogTopicLink = ({
	to: toProp,
	active: activeProp,
	topicData,
	currentTopicSlug,
	children,
	...rest
}) => {
	const { language: currentLanguage } = useIntlContext();

	const { to, name } =
		typeof toProp !== 'undefined'
			? { to: toProp, name: children, active: activeProp }
			: genLocalizedTopicLink({ currentLanguage, topicData });

	const active =
		activeProp || (topicData && currentTopicSlug === topicData.slug);

	return (
		<IntlLink
			sx={{
				color: active ? 'primary' : 'body',
			}}
			to={to}
			variant="nav"
			{...rest}
		>
			{name}
		</IntlLink>
	);
};
export default BlogTopicLink;
