import { defineMessages } from 'gatsby-theme-fast-ai-sidebar';

export default defineMessages({
	heading: {
		id: 'companies.heading',
		// defaultMessage: 'Trusted by',
		defaultMessage: 'Our clients',
	},
	downloadCaseStudyEbook: {
		id: 'companies.downloadCaseStudyEbook',
		defaultMessage: 'Download case study',
	},
	goToReferences: {
		id: 'companies.goToReferences',
		defaultMessage: 'View references',
	},
	perex: {
		id: 'companies.perex',
		defaultMessage:
			'Meet our successful clients in <strong>E-Commerce</strong> and <strong>Finance</strong>',
	},
});
