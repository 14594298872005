import useJustIsInViewport from '@fast-ai/ui/utils/useJustIsInViewport';
import { keyframes } from '@emotion/core';

const moveInAnimation = keyframes`
0% {
	transform: translateY(20%);
	opacity: 0;
}
100% {
	transform: translateY(0%);
	opacity: 1;
}
`;
const TextIn = ({
	children,
	sx,
	duration = '.5s',
	delay = '0s',
	offsetY = '20%',
	...rest
}) => {
	const { ref, shown } = useJustIsInViewport();

	return children({
		ref,
		sx: {
			'@media (prefers-reduced-motion)': {
				animationName: '',
			},
			transformOrigin: '50% 0',
			opacity: 0,
			// chrome mobile broken
			// willChange: 'transform',
			transform: `translateY(${offsetY})`,
			animationName: shown ? moveInAnimation : null,
			animationDuration: duration,
			animationDelay: delay,
			animationIterationCount: '1',
			animationFillMode: 'both',
			// NOTE: do not work well in mobile browsers for small durations
			// animationTimingFunction: 'ease-in',
			...sx,
		},
		...rest,
	});
};
export default TextIn;
