import { useRef, useState } from 'react';

import useIntersectionObserver from './useIntersectionObserver';

export default function useIsInViewport({ threshold, root, rootMargin } = {}) {
	const [inViewport, setInViewport] = useState(false);
	const [element, setElement] = useState(null);

	const setRef = newElement => {
		if (newElement && !element) {
		}
		setElement(newElement);
	};

	const callback = useRef(setInViewport);

	useIntersectionObserver(element, { threshold, root, rootMargin }, entries => {
		if (!callback.current) {
			return;
		}

		for (const entry of entries) {
			if (entry.target === element) {
				setInViewport(entry.isIntersecting);
			}
		}
	});

	return { inViewport, ref: setRef };
}
