import { Box, Col, Heading, Row, Text } from '@fast-ai/ui-components';
import React from 'react';

import u from '../intl/messages/ui';
import { Link, RichFormattedMessage } from '../intl';

import NavLink from './NavLink';

const label = <RichFormattedMessage {...u.more} />;
const Cards = ({ sx, items, clickAble, colProps, ...rest }) => (
	<Row
		sx={{
			flexWrap: 'wrap',
			height: '100%',
			alignItems: 'stretch',

			'article + article': { mt: [4, 4, 0] },
			...sx,
		}}
		{...rest}
	>
		{items.map(({ heading, icon, description, to }, i) => (
			<Col
				key={i}
				as="article"
				span={[12, 12, 6]}
				sx={{
					textAlign: ['center', 'center', 'left'],
				}}
				{...colProps}
			>
				<Box
					as={clickAble ? Link : 'div'}
					to={clickAble ? to : null}
					sx={{
						textDecoration: 'none',
						display: 'flex',
						flexDirection: 'column',
						flexWrap: 'wrap',
						backgroundColor: 'backgroundPrimary',
						border: t => t.borders.bold,
						alignItems: ['center', 'center', 'flex-start'],
						p: 4,
						height: '100%',
						transition: 'transform 400ms ease-out',
						transform: 'scale(1,1)',
						'&:hover': {
							transform: 'scale(1.05,1.05)',
						},
					}}
				>
					{icon}
					<Heading variant="subHeading1">{heading}</Heading>
					<Text as="p">{description}</Text>
					<Box>
						{clickAble ? (
							<NavLink as="span" label={label} />
						) : (
							<NavLink to={to} label={label} />
						)}
					</Box>
				</Box>
			</Col>
		))}
	</Row>
);
export default Cards;
