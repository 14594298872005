// /* global __PATH_PREFIX__, __BASE_PATH__ */
import React, { useCallback, useId, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import GlobeIcon from '@fast-ai/dashboard-icons/src/Programming3Asset15';
import { FormattedMessage, NoIntlLink } from 'gatsby-theme-fast-ai-sidebar';
import { useIntlContext } from 'gatsby-theme-fast-ai-sidebar/src/intl';
import { Link as GatsbyLink } from 'gatsby';
import Popper from '@fast-ai/ui/Popper';
import ClickAwayListener from '@fast-ai/ui/ClickAwayListener';
import Icon from '@fast-ai/dashboard-icons/src/Icon';
import { Box } from '@fast-ai/ui-components';

import l from '../intl/messages/languages';
import { ButtonWithIcon, Menu, MenuItem, MenuPopperWrapper } from '../ui';

const changeLocale = language => {
	localStorage.setItem('gatsby-intl-language', language);
};

const FakeRadio = ({ checked, ...rest }) => (
	<Icon {...rest}>
		{checked ? (
			<path d="M12 7c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5zm0-5C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z" />
		) : (
			<path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z" />
		)}
	</Icon>
);
const LanguageSwitcher = ({ sx, variant = 'ghost' }) => {
	const id = useId();
	const buttonRef = useRef();
	const containerRef = useRef();
	const [isOpened, setIsOpened] = useState(false);
	const handleClickAway = useCallback(() => setIsOpened(false), [setIsOpened]);
	const {
		language: currentLanguage,
		languages,
		routeAlternates,
		defaultLanguage,
	} = useIntlContext();

	const availableAlternates = routeAlternates
		? //  contains alternates for /en/xxx and /xxx (if the "en" is default)
		  routeAlternates.filter(x => x.lang !== defaultLanguage || x.default)
		: null;
	const listboxId = `listbox-${id}`;
	const triggerId = `trigger-${id}`;

	return (
		<Box sx={sx} ref={containerRef}>
			{languages && languages.length && (
				/*
					<Button
						id={triggerId}
						ref={buttonRef}
						onClick={() => {
							setIsOpened(s => !s);
						}}
						sx={{ p: 2, lineHeight: 1 }}
						icon={<GlobeIcon size={18} />}
						variant="outlinePrimary"
						aria-haspopup="listbox"
						aria-expanded={isOpened ? 'true' : 'false'}
					>
						<FormattedMessage {...l[currentLanguage]} />
					</Button>

				*/
				<ButtonWithIcon
					id={triggerId}
					ref={buttonRef}
					onClick={() => {
						setIsOpened(s => !s);
					}}
					label={<FormattedMessage {...l[currentLanguage]} />}
					textProps={{ variant: 'nav' }}
					sx={{ p: 2 }}
					icon={<GlobeIcon size={18} />}
					variant={variant}
					aria-haspopup="listbox"
					aria-expanded={isOpened ? 'true' : 'false'}
				/>
			)}

			<Popper
				anchorEl={buttonRef.current}
				container={containerRef.current}
				isOpened={isOpened}
				style={{ zIndex: 1200 }}
			>
				<ClickAwayListener onClickAway={handleClickAway}>
					<MenuPopperWrapper>
						<Menu
							id={listboxId}
							role="listbox"
							tabIndex="0"
							aria-labelledby={triggerId}
							sx={{
								flexDirection: 'column',
								minWidth: '200px',
							}}
						>
							{availableAlternates.map(({ lang, route }) => {
								const isActive = lang === currentLanguage;

								return (
									<MenuItem
										role="none"
										key={lang}
										sx={{
											':hover': {
												borderRadius: 'basic',
												backgroundColor: 'gray.1',
											},
										}}
									>
										<NoIntlLink
											role="option"
											lang={lang}
											aria-selected={isActive}
											variant="nav"
											as={GatsbyLink}
											onClick={() => changeLocale(lang)}
											to={route}
											sx={{ display: 'flex', alignItems: 'center', gap: 2 }}
										>
											<FakeRadio checked={isActive} />
											<FormattedMessage {...l[lang]} />
										</NoIntlLink>
									</MenuItem>
								);
							})}
						</Menu>
					</MenuPopperWrapper>
				</ClickAwayListener>
			</Popper>
		</Box>
	);
};
LanguageSwitcher.propTypes = { sx: PropTypes.object };

/*
					<TransparentSelect
						name="language"
						onChange={event => changeLocale(event.target.value)}
						value={currentLanguage}
						{...rest}
					>
						{languages.map(language => (
							<option key={language} value={language}>
								{language}
							</option>
						))}
					</TransparentSelect>
					*/
export default LanguageSwitcher;
