import React, { forwardRef, memo } from 'react';

import Icon from '../Icon';

export default function createSvgIcon(path, size, displayName) {
	const IconComponent = (props, ref) => (
		<Icon ref={ref} viewBox={`0 0 ${size} ${size}`} {...props}>
			{path}
		</Icon>
	);

	if (process.env.NODE_ENV !== 'production') {
		IconComponent.displayName = `${displayName}Icon`;
	}

	return memo(forwardRef(IconComponent));
}
