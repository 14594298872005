import { cloneElement, forwardRef, isValidElement, useState } from 'react';
import { useBlockingEffect } from '@fast-ai/ui-components';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

import useForkRef from '../utils/useForkRef';
import setRef from '../utils/setRef';

function getContainer(container) {
	return typeof container === 'function' ? container() : container;
}

/**
 * Portals provide a first-class way to render children into a DOM node
 * that exists outside the DOM hierarchy of the parent component.
 */
const Portal = forwardRef(({ children, container, disablePortal }, ref) => {
	const [mountNode, setMountNode] = useState(null);
	const handleRef = useForkRef(
		isValidElement(children) ? children.ref : null,
		ref
	);

	useBlockingEffect(() => {
		if (!disablePortal) {
			setMountNode(getContainer(container) || document.body);
		}
	}, [container, disablePortal]);

	useBlockingEffect(() => {
		if (mountNode && !disablePortal) {
			setRef(ref, mountNode);
			return () => {
				setRef(ref, null);
			};
		}

		return undefined;
	}, [ref, mountNode, disablePortal]);

	if (disablePortal) {
		if (isValidElement(children)) {
			return cloneElement(children, {
				ref: handleRef,
			});
		}
		return children;
	}

	return mountNode ? createPortal(children, mountNode) : mountNode;
});

Portal.propTypes = {
	/** Children to be rendered in the main container. */
	children: PropTypes.node,
	/**
	 * A HTML element or function that returns one.
	 * The `container` will have the portal children appended to it.
	 *
	 * By default, it uses the body of the top-level document object,
	 * so it's simply `document.body` most of the time.
	 */
	container: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
	/**
	 * The `children` will be inside the DOM hierarchy of the parent component.
	 */
	disablePortal: PropTypes.bool,
};

Portal.displayName = 'Portal';

export default Portal;
