import React from 'react';
import GatsbyCssBaselineTheme from 'gatsby-theme-fast-ai-sidebar/src/components/GatsbyCssBaseline';

// NOTE: setting height to auto not to 100%. Otherwise react-remove-scroll will
// not maintain overflow scroll
const GatsbyCssBaseline = ({ styles, ...rest }) => (
	<GatsbyCssBaselineTheme
		styles={`
			${styles}

		html {overflow-x: hidden;}
		html, body {min-height: 100%; height: auto;}
			`}
		{...rest}
	/>
);

export default GatsbyCssBaseline;
