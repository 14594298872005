import { defineMessages } from 'gatsby-theme-fast-ai-sidebar';

export default defineMessages({
	openInNewWindow: {
		id: 'ui.openInNewWindow',
		defaultMessage: 'Open in new window',
	},
	backToTop: {
		id: 'ui.backToTop',
		defaultMessage: 'Back to top',
	},
	more: {
		id: 'ui.more',
		defaultMessage: 'More',
	},
});
