import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { StringOrNumber } from '@fast-ai/react-types';
import { useGeneratedId } from '@fast-ai/ui-components';

/** Accessible SVG Icon */
const Icon = forwardRef(
	(
		{
			size = 24,
			title,
			description,
			id: idProp,
			idTitleSuffix = '-desc',
			idDescriptionSuffix = '-desc',
			children,
			width: widthProp,
			height: heightProp,
			...rest
		},
		ref
	) => {
		const generatedId = useGeneratedId();
		const id = idProp || generatedId;
		const idTitle = id + idTitleSuffix;
		const idDescription = id + idDescriptionSuffix;

		const width = widthProp || size;
		const height = heightProp || size;

		return (
			<svg
				{...(title
					? {
							'aria-labelledby': idTitle,
					  }
					: {})}
				{...(description
					? {
							'aria-describedby': idDescription,
					  }
					: {})}
				id={id}
				role="img"
				ref={ref}
				xmlns="http://www.w3.org/2000/svg"
				viewBox={`0 0 ${width} ${height}`}
				width={width}
				height={height}
				color="inherit"
				fill="currentcolor"
				{...rest}
			>
				{title && <title id={idTitle}>{title}</title>}
				{description && <desc id={idDescription}>{description}</desc>}
				{children}
			</svg>
		);
	}
);
Icon.displayName = 'Icon';

Icon.propTypes = {
	children: PropTypes.node,
	/** Convenient prop for setting SVG description */
	description: PropTypes.node,
	height: StringOrNumber,
	id: StringOrNumber,
	idDescriptionSuffix: PropTypes.string,
	idTitleSuffix: PropTypes.string,
	/** Default value for width and height */
	size: StringOrNumber,
	/** Convenient prop for setting SVG title */
	title: PropTypes.node,
	width: StringOrNumber,
};

export default Icon;
