/* eslint-disable react/prop-types,react/display-name */
import React, { Fragment } from 'react';
import { keyframes } from '@emotion/core';
import { Box, Heading } from '@fast-ai/ui-components';
import { GatsbyImage, getImage } from 'gatsby-theme-fast-ai-sidebar';
import DownloadIcon from '@fast-ai/dashboard-icons/src/Download';
import KeyboardArrowRightIcon from '@fast-ai/dashboard-icons/src/KeyboardArrowRight';
import { graphql, useStaticQuery } from 'gatsby';

import { Link, RichFormattedMessage } from '../intl';
import m from '../intl/messages/companies';
import r from '../intl/messages/routes';

import { ButtonWithIcon } from './helpers';

// import { ButtonWithIcon } from './helpers';

const marquee = keyframes`
  to {
    transform: translateX(-50%);
  }
`;

const logos = [
	{ img: 'hrkLogo', imgTitle: 'heurekaLogo - logo' },
	{ img: 'plkLogo', imgTitle: 'Pilulka - logo' },
	{ img: 'dcdLogo', imgTitle: 'DecoDoma - logo' },
	{ img: 'atxLogo', imgTitle: 'Astratex - logo' },
	{ img: 'algLogo', imgTitle: 'Allegria - logo' },
	{ img: 'ctzLogo', imgTitle: 'Cityzen - logo' },
	{ img: 'essoxLogo', imgTitle: 'Essox - logo' },
	{ img: 'monetaLogo', imgTitle: 'Moneta - logo' },
	{ img: 'generaliLogo', imgTitle: 'Generali - logo' },
	//
	{ img: 'hrkLogo', imgTitle: 'heurekaLogo - logo' },
	{ img: 'plkLogo', imgTitle: 'Pilulka - logo' },
	{ img: 'dcdLogo', imgTitle: 'DecoDoma - logo' },
	{ img: 'atxLogo', imgTitle: 'Astratex - logo' },
	{ img: 'algLogo', imgTitle: 'Allegria - logo' },
	{ img: 'ctzLogo', imgTitle: 'Cityzen - logo' },
	{ img: 'essoxLogo', imgTitle: 'Essox - logo' },
	{ img: 'monetaLogo', imgTitle: 'Moneta - logo' },
	{ img: 'generaliLogo', imgTitle: 'Generali - logo' },
];

const CompaniesBrands = ({ hideActions, hideReferencesLink }) => {
	const data = useStaticQuery(graphql`
		{
			ctzLogo: file(relativePath: { eq: "ctz.png" }) {
				childImageSharp {
					gatsbyImageData(width: 60, layout: CONSTRAINED, placeholder: BLURRED)
				}
			}
			algLogo: file(relativePath: { eq: "alg.png" }) {
				childImageSharp {
					gatsbyImageData(width: 90, layout: CONSTRAINED, placeholder: BLURRED)
				}
			}
			dcdLogo: file(relativePath: { eq: "dcd.png" }) {
				childImageSharp {
					gatsbyImageData(width: 200, layout: CONSTRAINED, placeholder: BLURRED)
				}
			}
			plkLogo: file(relativePath: { eq: "plk.png" }) {
				childImageSharp {
					gatsbyImageData(width: 200, layout: CONSTRAINED, placeholder: BLURRED)
				}
			}
			atxLogo: file(relativePath: { eq: "atx.png" }) {
				childImageSharp {
					gatsbyImageData(width: 200, layout: CONSTRAINED, placeholder: BLURRED)
				}
			}
			hrkLogo: file(relativePath: { eq: "hrk.png" }) {
				childImageSharp {
					gatsbyImageData(width: 200, layout: CONSTRAINED, placeholder: BLURRED)
				}
			}
			monetaLogo: file(relativePath: { eq: "moneta-logo-bw.png" }) {
				childImageSharp {
					gatsbyImageData(width: 200, layout: CONSTRAINED, placeholder: BLURRED)
				}
			}
			essoxLogo: file(relativePath: { eq: "essox-logo-bw.png" }) {
				childImageSharp {
					gatsbyImageData(width: 200, layout: CONSTRAINED, placeholder: BLURRED)
				}
			}
			generaliLogo: file(relativePath: { eq: "gli.png" }) {
				childImageSharp {
					gatsbyImageData(width: 200, layout: CONSTRAINED, placeholder: BLURRED)
				}
			}
		}
	`);

	// NOTE: unfortunately we cannot use the import because it adds hash to a file
	// NOTE: we need to be able to change it without modifing the URL
	// import caseStudyEBook from '../../content/assets/Případová_studie_ZOE.AI_2024.pdf';
	// const ebookLink = `${location.origin}/Případová_studie_ZOE.AI_2024.pdf`;

	return (
		<Fragment>
			<Box
				sx={{
					textAlign: 'center',
				}}
			>
				<RichFormattedMessage {...m.perex} />
			</Box>

			<Box
				sx={{
					overflow: 'hidden',
					width: '100%',
					maxWidth: '100%',
					py: [2, 3, 4],
				}}
			>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						animation: `${marquee} 40s linear infinite`,
						willChange: 'transform',
						width: 'fit-content',
						position: 'relative',
					}}
				>
					{logos.map(({ imgTitle, img }, i) => (
						<Box
							key={img + i}
							sx={{
								px: 4,
								py: 0,
								width: 180,
								flexShrink: 0,
								flexGrow: 0,
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								opacity: 0.6,
								'.gatsby-image-wrapper': { mb: [0, 0, 0] },
							}}
						>
							<GatsbyImage
								image={getImage(data[img])}
								title={imgTitle}
								alt={imgTitle}
							/>
						</Box>
					))}
				</Box>
			</Box>

			{!hideActions && (
				<Box
					sx={{
						textAlign: 'center',
					}}
				>
					<Heading
						variant="subHeading2"
						sx={{
							display: 'none',
						}}
					>
						<RichFormattedMessage {...m.heading} />
					</Heading>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							flexDirection: ['column', 'row'],
							gap: [2, 4],
						}}
					>
						<ButtonWithIcon
							as={Link}
							variant="outline"
							to={r.downloadEbook}
							label={<RichFormattedMessage {...m.downloadCaseStudyEbook} />}
							icon={<DownloadIcon />}
						/>
						{!hideReferencesLink && (
							<ButtonWithIcon
								as={Link}
								variant="ghost"
								to={r.references}
								label={<RichFormattedMessage {...m.goToReferences} />}
								iconAfter={<KeyboardArrowRightIcon />}
								sx={{ gap: 0 }}
							/>
						)}
					</Box>
				</Box>
			)}
		</Fragment>
	);
};
export default CompaniesBrands;
