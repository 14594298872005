/* eslint-disable max-len */
import React, { Fragment } from 'react';

import createSvgIcon from './utils/createSvgIcon';

export default createSvgIcon(
	<Fragment>
		<g transform="translate(2.5 0) scale(1.03 1.03)">
			<g
				fill="none"
				stroke="currentColor"
				strokeMiterlimit="10"
				strokeWidth="2"
				data-name="Layer 2"
			>
				<path
					strokeDasharray="56 2 2 2 2 2"
					d="M16.73 24.41h0L13 30l-3.73-5.59h0a12 12 0 117.46 0z"
				/>
				<path d="M7 9h12v8H7z" />
				<path d="M7 10.43L13 13l6-2.57" />
			</g>
		</g>
	</Fragment>,
	32,
	'LocationAndMap1Asset23'
);
