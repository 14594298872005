import themeSidebar from 'gatsby-theme-fast-ai-sidebar/src/theme';

const grid = {
	maxColumns: 12,
	// guttersDense: [1, 1, 2, 3],
	// gutters: [1, 2, 3, 4],
	guttersDense: [2],
	gutters: [3],
};

const muted = {
	...themeSidebar.text.body,
	color: 'gray.3',
};

const breakpoints = ['40em', '58em', '98em'];
breakpoints.sm = breakpoints[0];
breakpoints.md = breakpoints[1];
breakpoints.lg = breakpoints[2];

const lightGray = [null, '#f6f6f6', '#efefef', '#eaeaea', '#e5e5e5'];
const severity = [
	null,
	themeSidebar.colors.secondary,
	'#FFCA23',
	'#FF9023',
	themeSidebar.colors.danger,
];

const backgroundPrimary = '#fff';
severity.low = severity[1];
severity.middle = severity[2];
severity.high = severity[3];
severity.critical = severity[4];

export default {
	...themeSidebar,
	fontSizes: [
		12,
		14,
		16, // was 18
		20,
		22,
		24,
		27,
		36,
		49,
		85,
	],
	breakpoints,
	// borders: {
	// 	...themeSidebar.borders,
	// 	nav: `1px solid ${themeSidebar.colors.primary}`,
	// },
	shadows: {
		...themeSidebar.shadows,
		nav: '0 0 32px rgba(0, 0, 0, .25)',
	},
	colors: {
		...themeSidebar.colors,
		severity,
		lightGray,
		backgroundPrimary,
		nav: themeSidebar.colors.gray[3],
	},
	grid,
	radii: {
		basic: '16px',
		circle: 999999,
	},
	buttons: {
		...themeSidebar.buttons,
		outlinePrimary: {
			...themeSidebar.buttons.outline,
			bg: 'transparent',
			boxShadow: `inset 0 0 0 4px ${themeSidebar.colors.primary}`,
			color: 'primary',
			':hover': {
				bg: 'primary',
				color: 'contrast',
			},
		},
		ghost: {
			...themeSidebar.buttons.outline,
			bg: 'transparent',
			boxShadow: 'none',
			color: 'body',
			':hover': {
				bg: 'body',
				color: 'white',
				boxShadow: `inset 0 0 0 4px ${themeSidebar.colors.body}`,
			},
		},
	},
	text: {
		...themeSidebar.text,
		body: {
			...themeSidebar.text.body,
			color: 'body',
			fontSize: [2, 2, 2, 4],
		},
		small: {
			fontSize: [1, 1, 1, 2],
			color: 'body',
		},
		muted,
		heading: {
			...themeSidebar.text.heading,
			pt: [2],
			mb: [4, 4, 5],
		},
		subHeading1: {
			...themeSidebar.text.subHeading1,
			fontSize: [4, 4, 6, 7],
			pt: [2, 2, 2, 2],
		},
		subHeading2: {
			...themeSidebar.text.subHeading2,
			fontSize: [2, 2, 4, 6],
			pt: [2, 2, 2, 2],
		},
	},
	zIndex: {
		sidebar: 6000,
		header: 7000,
		cookieBar: 8000,
		modal: 9000,
	},
	root: {},
	MiniGuide: {
		width: 64,
	},
	Header: {
		zIndex: 8999,
		height: 64,
		// borderBottom: theme => theme.separatorBorder.normal,
	},
	DesktopNavigationMenu: {
		minWidth: 498,
	},
	Sidebar: {
		width: '100%',
		slideOffset: '300px',
	},
	variants: {
		...themeSidebar.variants,
		stripesPrimary: {
			// ...themeSidebar.variants.stripes,
			backgroundColor: backgroundPrimary,
			backgroundImage: 'none',
		},
		stripesSecondary: {
			// ...themeSidebar.variants.stripes,
			backgroundColor: 'lightGray.1',
		},
		muted,
		container: {
			margin: '0 auto',
			maxWidth: ['none', '40em', '58em', '82em'],
		},
		cookieBarModelFooter: {
			py: 3,
			borderTop: t => t.borders.normal,
			backgroundColor: 'backgroundPrimary',
		},
	},
};
