import PropTypes from 'prop-types';

export const Ref = PropTypes.oneOfType([
	// Either a function
	PropTypes.func,
	// Or the instance of a DOM native element (see the note about SSR)
	PropTypes.shape({ current: PropTypes.any }),
]);

export const StringOrNumber = PropTypes.oneOfType([
	PropTypes.string,
	PropTypes.number,
]);

/** @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat */
export const IntlNumberFormatOptions = PropTypes.shape({
	style: PropTypes.string,
});

export const Locale = PropTypes.shape({
	country: PropTypes.string,
	/** either language or language-country `cs` or `cs-cz` */
	language: PropTypes.string,
});

export const IntlMessage = PropTypes.shape({
	id: PropTypes.string.isRequired,
	defaultMessage: PropTypes.string,
});

export const FormattedNumberProps = {
	intlNumberFormat: IntlNumberFormatOptions,
	max: PropTypes.number,
	min: PropTypes.number,
	value: PropTypes.number,
};

export const FormattedNumber = PropTypes.shape(FormattedNumberProps);
