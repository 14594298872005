import { useEffect, useMemo } from 'react';

import useEventCallback from './useEventCallback';

const canUseObserver =
	typeof document !== 'undefined' &&
	typeof IntersectionObserver !== 'undefined';

export default function useIntersectionObserver(
	element,
	{ root, rootMargin, threshold },
	callbackArg
) {
	const callback = useEventCallback(callbackArg);

	const observer = useMemo(
		() =>
			canUseObserver
				? new IntersectionObserver(callback, { root, rootMargin, threshold })
				: null,
		[
			callback,
			root,
			rootMargin,
			// NOTE: threshold can be number of array of numbers
			threshold && JSON.stringify(threshold),
		]
	);

	useEffect(() => {
		if (!element || !observer) {
			return;
		}
		observer.observe(element);

		return () => observer.unobserve;
	}, [observer, element]);
}
