import { useCallback, useRef } from 'react';
import { useBlockingEffect } from '@fast-ai/ui-components';
/**
 * https://github.com/facebook/react/issues/14099#issuecomment-440013892
 * @param {function} fn
 */
export default function useEventCallback(fn) {
	const ref = useRef(fn);
	useBlockingEffect(() => {
		ref.current = fn;
	});
	return useCallback((...args) => (0, ref.current)(...args), []);
}
