import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

const wrapWithProvider = ({ element }) => (
	<Fragment>
		<Helmet>
			<script
				src="https://assets.calendly.com/assets/external/widget.js"
				defer
				type="text/javascript"
			/>
			<link
				rel="stylesheet"
				href="https://assets.calendly.com/assets/external/widget.css"
			/>
			<meta
				name="google-site-verification"
				content="MKfMd1mG7_CIA7fWFf0JJGqEb7Rdu5YQjt53_hI7LlE"
			/>
		</Helmet>
		{element}
	</Fragment>
);

wrapWithProvider.propTypes = { element: PropTypes.node };

export default wrapWithProvider;
