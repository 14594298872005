import React, { Fragment } from 'react';
import { graphql, useStaticQuery, withPrefix } from 'gatsby';
import { GatsbySeo, LogoJsonLd } from 'gatsby-plugin-next-seo';
import { useIntl } from 'gatsby-theme-fast-ai-sidebar';
import { useIntlContext } from 'gatsby-theme-fast-ai-sidebar/src/intl';

const m = {
	siteDescription: {
		id: 'siteMetadata.description',
		defaultMessage: '',
	},
	siteTitle: {
		id: 'siteMetadata.title',
		defaultMessage: '',
	},
};

const Seo = ({
	title: titleProp,
	description,
	ogImage: ogImageProp,
	...rest
}) => {
	const {
		site: { siteMetadata },
		logo256: {
			childImageSharp: { fixed: logo256 },
		},
		defaultOgImage1200: {
			childImageSharp: { fixed: defaultOgImage1200 },
		},
		// defaultOgImage800: {
		// 	childImageSharp: { fixed: defaultOgImage800 },
		// },
		// defaultOgImage256: {
		// 	childImageSharp: { fixed: defaultOgImage256 },
		// },
	} = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						author
						title
						description
						url
					}
				}
				defaultOgImage1200: file(relativePath: { eq: "og-landing.png" }) {
					childImageSharp {
						fixed(width: 1200) {
							src
							width
							height
						}
					}
				}
				defaultOgImage800: file(relativePath: { eq: "og-landing.png" }) {
					childImageSharp {
						fixed(width: 800) {
							src
							width
							height
						}
					}
				}
				defaultOgImage256: file(relativePath: { eq: "og-landing.png" }) {
					childImageSharp {
						fixed(width: 256) {
							src
							width
							height
						}
					}
				}
				logo256: file(relativePath: { eq: "zoe-ai-logo.png" }) {
					childImageSharp {
						fixed(width: 256) {
							src
							width
							height
						}
					}
				}
			}
		`
	);
	const ogImage = ogImageProp?.childImageSharp?.fixed;

	const baseUrl = siteMetadata.url;

	const withPrefixAndUrl = pathname =>
		`${baseUrl.replace(/\/$/, '')}/${withPrefix(pathname).replace(/^\//, '')}`;

	const intl = useIntl();

	const metaDescription =
		intl.formatMessage(m.siteDescription) || siteMetadata.description;

	const metaTitle = intl.formatMessage(m.siteTitle) || siteMetadata.title;
	const intlContext = useIntlContext();
	const { routeAlternates, language, defaultLanguage, originalPath, routed } =
		intlContext;

	const currentPath = intlContext.routed
		? `/${language}${intlContext.originalPath}`
		: originalPath;

	const canonical = withPrefixAndUrl(
		defaultLanguage === language && routed ? originalPath : currentPath
	);
	const url = withPrefixAndUrl(currentPath);

	const ogAlt = titleProp || metaTitle;
	return (
		<Fragment>
			<LogoJsonLd url={baseUrl} logo={withPrefixAndUrl(logo256.src)} />
			<GatsbySeo
				language={intl.locale}
				title={titleProp}
				titleTemplate={metaTitle ? `%s | ${metaTitle}` : void 0}
				description={description || metaDescription || ''}
				canonical={canonical}
				languageAlternates={routeAlternates.map(
					({ lang, default: isDefault, route }) => ({
						hrefLang: isDefault ? 'x-default' : lang,
						href: withPrefixAndUrl(route),
					})
				)}
				openGraph={{
					url,
					locale: intlContext.language,
					// NOTE: following will be reused in Twitter meta
					title: titleProp || metaTitle,
					description: description || metaDescription || '',
					images: [
						ogImage
							? {
								url: withPrefixAndUrl(ogImage.src),
								width: ogImage.width,
								height: ogImage.height,
								alt: ogAlt,
							}
							: {
								url: withPrefixAndUrl(defaultOgImage1200.src),
								width: defaultOgImage1200.width,
								height: defaultOgImage1200.height,
								alt: ogAlt,
							},
						// {
						// 	url: withPrefixAndUrl(defaultOgImage800.src),
						// 	width: defaultOgImage800.width,
						// 	height: defaultOgImage800.height,
						// 	alt: 'Zoe.ai banner',
						// },
						// {
						// 	url: withPrefixAndUrl(defaultOgImage256.src),
						// 	width: defaultOgImage256.width,
						// 	height: defaultOgImage256.height,
						// 	alt: 'Zoe.ai banner',
						// },
						// {
						// 	url: withPrefixAndUrl(logo256.src),
						// 	width: logo256.width,
						// 	height: logo256.height,
						// 	alt: 'Zoe.ai logo',
						// },
					],
				}}
				{...rest}
			/>
		</Fragment>
	);
};

export default Seo;
