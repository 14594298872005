import React, { Fragment, cloneElement, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import getOwnerDocument from '../utils/getOwnerDocument';
import useForkRef from '../utils/useForkRef';
import useEventCallback from '../utils/useEventCallback';

const mapEventPropToEvent = eventProp => eventProp.substring(2).toLowerCase();

const clickedRootScrollbar = (event, doc) =>
	doc.documentElement.clientWidth < event.clientX ||
	doc.documentElement.clientHeight < event.clientY;

/**
 * Listen for click events that occur somewhere in the document,
 * outside of the element itself.
 * For instance, if you need to hide a menu when people click
 * anywhere else on your page.
 *
 * Fork of https://github.com/mui-org/material-ui/blob/next/packages/material-ui/src/ClickAwayListener/ClickAwayListener.js
 */
function ClickAwayListener({
	children,
	disableReactTree,
	mouseEvent = 'onClick',
	onClickAway,
	touchEvent = 'onTouchEnd',
}) {
	const movedRef = useRef(false);
	const nodeRef = useRef(null);
	const activatedRef = useRef(false);
	const syntheticEventRef = useRef(false);

	useEffect(() => {
		// Ensure that this component is not "activated" synchronously.
		// https://github.com/facebook/react/issues/20074
		setTimeout(() => {
			activatedRef.current = true;
		}, 0);
		return () => {
			activatedRef.current = false;
		};
	}, []);

	const handleRef = useForkRef(children.ref, nodeRef);

	// The handler doesn't take event.defaultPrevented into account:
	//
	// event.preventDefault() is meant to stop default behaviors like
	// clicking a checkbox to check it, hitting a button to submit a form,
	// and hitting left arrow to move the cursor in a text input etc.
	// Only special HTML elements have these default behaviors.
	const handleClickAway = useEventCallback(event => {
		// Given developers can stop the propagation of the synthetic event,
		// we can only be confident with a positive value.
		const insideReactTree = syntheticEventRef.current;
		syntheticEventRef.current = false;

		const doc = getOwnerDocument(nodeRef.current);

		// 1. IE11 support, which trigger the handleClickAway even after the unbind
		// 2. The child might render null.
		// 3. Behave like a blur listener.
		if (
			!activatedRef.current ||
			!nodeRef.current ||
			clickedRootScrollbar(event, doc)
		) {
			return;
		}

		// Do not act if user performed touchmove
		if (movedRef.current) {
			movedRef.current = false;
			return;
		}

		let insideDOM;

		// If not enough, can use https://github.com/DieterHolvoet/event-propagation-path/blob/master/propagationPath.js
		if (event.composedPath) {
			insideDOM = event.composedPath().indexOf(nodeRef.current) > -1;
		} else {
			insideDOM =
				!doc.documentElement.contains(event.target) ||
				nodeRef.current.contains(event.target);
		}

		if (!insideDOM && (disableReactTree || !insideReactTree)) {
			onClickAway(event);
		}
	});

	// Keep track of mouse/touch events that bubbled up through the portal.
	const createHandleSynthetic = handlerName => event => {
		syntheticEventRef.current = true;

		const childrenPropsHandler = children.props[handlerName];
		if (childrenPropsHandler) {
			childrenPropsHandler(event);
		}
	};

	const childrenProps = { ref: handleRef };

	if (touchEvent !== false) {
		childrenProps[touchEvent] = createHandleSynthetic(touchEvent);
	}

	useEffect(() => {
		if (touchEvent !== false) {
			const mappedTouchEvent = mapEventPropToEvent(touchEvent);
			const doc = getOwnerDocument(nodeRef.current);

			const handleTouchMove = () => {
				movedRef.current = true;
			};

			doc.addEventListener(mappedTouchEvent, handleClickAway);
			doc.addEventListener('touchmove', handleTouchMove);

			return () => {
				doc.removeEventListener(mappedTouchEvent, handleClickAway);
				doc.removeEventListener('touchmove', handleTouchMove);
			};
		}

		return undefined;
	}, [handleClickAway, touchEvent]);

	if (mouseEvent !== false) {
		childrenProps[mouseEvent] = createHandleSynthetic(mouseEvent);
	}

	useEffect(() => {
		if (mouseEvent !== false) {
			const mappedMouseEvent = mapEventPropToEvent(mouseEvent);
			const doc = getOwnerDocument(nodeRef.current);

			doc.addEventListener(mappedMouseEvent, handleClickAway);

			return () => {
				doc.removeEventListener(mappedMouseEvent, handleClickAway);
			};
		}

		return undefined;
	}, [handleClickAway, mouseEvent]);

	return <Fragment>{cloneElement(children, childrenProps)}</Fragment>;
}

ClickAwayListener.propTypes = {
	/**
	 * The wrapped element.
	 */
	children: PropTypes.node,
	/**
	 * If `true`, the React tree is ignored and only the DOM tree is considered.
	 * This prop changes how portaled elements are handled.
	 */
	disableReactTree: PropTypes.bool,
	/**
	 * The mouse event to listen to. You can disable
	 * the listener by providing `false`.
	 */
	mouseEvent: PropTypes.oneOf(['onClick', 'onMouseDown', 'onMouseUp', false]),
	/**
	 * Callback fired when a "click away" event is detected.
	 */
	onClickAway: PropTypes.func.isRequired,
	/**
	 * The touch event to listen to.
	 * You can disable the listener by providing `false`.
	 */
	touchEvent: PropTypes.oneOf(['onTouchEnd', 'onTouchStart', false]),
};

export default ClickAwayListener;
