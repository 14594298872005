import { useIntl } from 'gatsby-theme-fast-ai-sidebar';

export const useTranslateMessage = () => {
	const { formatMessage } = useIntl();

	/**
	 * @param {any} fallback
	 * @param {MessageDescriptor|[MessageDescriptor,Record<string,
	 * MessageFormatPrimitiveValue>]} messageWithValues
	 */
	const translate = (messageWithValues, { silentMode, fallback } = {}) => {
		if (messageWithValues == null || typeof messageWithValues === 'string') {
			return messageWithValues;
		}

		let values;
		let message;
		if (Array.isArray(messageWithValues)) {
			message = messageWithValues[0];
			values = messageWithValues[1];
		} else {
			message = messageWithValues;
		}

		if (message && typeof message.id !== 'undefined') {
			return formatMessage(message, values);
		}

		if (silentMode) {
			return fallback;
		}

		throw 'Invalid message.';
	};

	return translate;
};
