import KeyboardArrowRight from '@fast-ai/dashboard-icons/custom/KeyboardArrowRight';
import React from 'react';
import { Box } from '@fast-ai/ui-components';

import { Link } from '../intl';

const NavLink = ({
	label,
	children,
	sx,
	renderIcon: Icon = KeyboardArrowRight,
	...rest
}) => (
	<Link
		variant="nav"
		sx={{
			px: 0,
			mx: 0,
			whiteSpace: 'nowrap',
			textTransform: 'none',
			...sx,
		}}
		{...rest}
	>
		{label || children}
		{' '}
		<Box as={Icon} sx={{ display: 'inline', verticalAlign: 'middle' }} />
	</Link>
);

export default NavLink;
