import { defineMessages } from 'gatsby-theme-fast-ai-sidebar';

export default defineMessages({
	czechRepublic: {
		id: 'contacts.czechRepublic',
		defaultMessage: 'Czech republic',
	},
	cityPrague: {
		id: 'contacts.cityPrague',
		defaultMessage: 'Prague',
	},
	companyIN: {
		id: 'contacts.companyIN',
		defaultMessage: 'IN: {companyIN}',
	},
});
