import ExternalLink from '@fast-ai/ui/ExternalLink';
import React from 'react';

import { useTranslateMessage } from './hooks';

const Link = ({ to, ...rest }) => {
	const translate = useTranslateMessage();

	return <ExternalLink to={translate(to)} {...rest} />;
};
export default Link;
