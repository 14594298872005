/* eslint-disable react/prop-types,react/display-name */
import React, { forwardRef } from 'react';
import { Box, Button, Container, Text } from '@fast-ai/ui-components';

export const MobileBox = ({ display = 'block', ...rest }) => (
	<Box
		display={
			[display, display, 'none'] /* can't be overwritten by sx in rebass*/
		}
		{...rest}
	/>
);

export const DesktopBox = forwardRef(({ display = 'block', ...rest }, ref) => (
	<Box
		ref={ref}
		display={
			['none', 'none', display] /* can't be overwritten by sx in rebass*/
		}
		{...rest}
	/>
));

export const DesktopFlex = forwardRef(({ display = 'flex', ...rest }, ref) => (
	<DesktopBox ref={ref} display={display} {...rest} />
));

export const MobileFlex = ({ display = 'flex', ...rest }) => (
	<MobileBox display={display} {...rest} />
);

export const Menu = ({ sx, ...rest }) => (
	<Box as="ul" sx={{ p: 0, m: 0, display: 'flex', ...sx }} {...rest} />
);

export const MenuItemLabel = forwardRef(
	({ label, sx, active, ...rest }, ref) => (
		<Box
			ref={ref}
			variant="nav"
			sx={{
				display: 'block',
				color: active ? 'primary' : 'body',
				...sx,
			}}
			{...rest}
		>
			{label}
		</Box>
	)
);

export const MenuPopperWrapper = forwardRef(({ sx, ...rest }, ref) => (
	<Box
		ref={ref}
		sx={{
			backgroundColor: 'backgroundPrimary',
			border: theme => theme.borders.normal,
			boxShadow: 'nav',
			borderRadius: 'basic',
			overflow: 'hidden',
			p: [2],
			...sx,
		}}
		{...rest}
	/>
));

export const MenuItem = forwardRef(({ sx, ...rest }, ref) => (
	<Box
		ref={ref}
		as="li"
		sx={{ p: 0, m: 0, display: 'block', ...sx }}
		{...rest}
	/>
));

export const Strong = forwardRef(({ sx, ...rest }, ref) => (
	<Box ref={ref} as="strong" sx={{ fontWeight: 'bold', ...sx }} {...rest} />
));

export const Primary = ({ sx, ...rest }) => (
	<Box
		as="span"
		sx={{ color: 'primary', fontWeight: 'bold', ...sx }}
		{...rest}
	/>
);

export const GatsbyDashedCircleImage = ({ sx, ...rest }) => (
	<Box
		sx={{
			...sx,
			'.gatsby-image-wrapper': {
				borderRadius: 'circle',
				border: theme => `8px dashed ${theme.colors.primary}`,
				overflow: 'hidden',
				// NOTE: in Safari broken radius clipping of image does not work
				'[data-main-image]': {
					transform: 'none!important',
					willChange: 'auto!important',
				},
				...(sx && sx['.gatsby-image-wrapper']),
			},
		}}
		{...rest}
	/>
);
export const GatsbyCircleImage = ({ sx, ...rest }) => (
	<Box
		sx={{
			...sx,
			'.gatsby-image-wrapper': {
				borderRadius: 'circle',
				overflow: 'hidden',
				...(sx && sx['.gatsby-image-wrapper']),
			},
		}}
		{...rest}
	/>
);

export const ButtonWithIcon = forwardRef(
	(
		{ label, icon, iconBefore, iconAfter, sx, textProps = {}, ...rest },
		ref
	) => {
		const { sx: sxText, ...restText } = textProps;
		return (
			<Button
				ref={ref}
				sx={{
					display: 'flex',
					alignItems: 'center',
					gap: 2,
					...sx,
				}}
				{...rest}
			>
				{icon || iconBefore ? icon || iconBefore : null}
				{label ? (
					<Text
						sx={{ color: 'inherit', whiteSpace: 'nowrap', ...sxText }}
						{...restText}
					>
						{label}
					</Text>
				) : null}
				{iconAfter ? iconAfter : null}
			</Button>
		);
	}
);

export const CalendlyButton = ({ onClick, user, event, ...rest }) => (
	<ButtonWithIcon
		onClick={(...args) => {
			if (typeof window.Calendly !== 'undefined') {
				window.Calendly.showPopupWidget(
					`https://calendly.com/${user}/${event}`
				);
			}
			onClick?.(...args);
		}}
		{...rest}
	/>
);

export const Section = forwardRef(
	({ children, sx, styles = {}, ...rest }, ref) => (
		<Box sx={{ ...sx, ...styles.root }} {...rest} ref={ref}>
			<Container
				as="section"
				sx={{
					position: 'relative',
					textAlign: 'center',
					py: [5, 5, 5],
					...styles.container,
				}}
			>
				{children}
			</Container>
		</Box>
	)
);

export const DotMenuItem = ({ children, sx, ...rest }) => (
	<Box
		as="li"
		sx={{
			display: 'block',
			position: 'relative',
			'.dot': {
				opacity: 1,
				transform: 'rotate(-15deg)',
			},
			':hover': {
				'.dot': {
					opacity: 0.5,
					transform: 'rotate(70deg)',
				},
			},
			...sx,
		}}
		{...rest}
	>
		<Box
			className="dot"
			sx={{
				position: 'absolute',
				top: '9px',
				left: '2px',
				display: 'inline-flex',
				width: '12px',
				height: '12px',
				backgroundColor: 'primary',
				borderRadius: '2px',
				transition:
					'transform .175s ease-in-out, background-color .175s ease-in-out',
			}}
		/>
		<Box pl={4}>{children}</Box>
	</Box>
);
export const BlockSpan = ({ sx, ...rest }) => (
	<Text as="span" sx={{ display: 'block', ...sx }} {...rest} />
);

export const P = ({ sx, ...rest }) => (
	<Text as="p" sx={{ ...sx, mb: 2 }} {...rest} />
);

export const ImageTitle = ({ sx, children, ...rest }) => (
	<Box sx={{ ...sx, textAlign: 'center', p: { mb: 2 } }} {...rest}>
		{children}
	</Box>
);
