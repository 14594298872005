import React from 'react';
import { FormattedMessage } from 'gatsby-theme-fast-ai-sidebar';
import { Paragraph } from 'gatsby-theme-fast-ai-sidebar/src/mdxComponents';

import { Primary, Strong } from '../ui';

const RichFormattedMessage = ({ values, ...rest }) => (
	<FormattedMessage
		{...rest}
		values={{
			/* eslint-disable react/display-name */
			strong: chunks => <Primary>{chunks}</Primary>,
			b: chunks => <Strong>{chunks}</Strong>,
			p: chunks => <Paragraph>{chunks}</Paragraph>,
			br: <br />,
			/* eslint-enable react/display-name */
			...values,
		}}
	/>
);
export default RichFormattedMessage;
