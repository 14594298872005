/* eslint-disable max-len */
import React, { Fragment } from 'react';

import createSvgIcon from './utils/createSvgIcon';

export default createSvgIcon(
	<Fragment>
		<g
			fill="none"
			stroke="currentColor"
			strokeMiterlimit="10"
			strokeWidth="2"
			data-name="Layer 2"
		>
			<path d="M1 11V6l5-5h7v9.19H7v11.62h6V31H6l-5-5v-5M1 15v-2M1 19v-2" />
			<circle cx="24" cy="10" r="4" />
			<path d="M17 26v-6a6 6 0 016-6h2a6 6 0 016 6v6zM24 17v6" />
		</g>
	</Fragment>,
	32,
	'BusinessAndFinance1Asset3'
);
