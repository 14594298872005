/* eslint-disable max-len */
import React, { forwardRef } from 'react';

import SvgIcon from './Icon';

const IconDownload = forwardRef((props, ref) => (
	<SvgIcon viewBox="0 0 32 32" ref={ref} {...props}>
		<path d="M0.205078 19.3428H3.40508V25.7428H29.0051V19.3428H32.2051V28.9428H0.205078V19.3428Z" />
		<path d="M14.6052 17.08V3.34277H17.8052V17.08L21.4738 13.4114L23.7366 15.6741L16.2052 23.2055L8.67383 15.6741L10.9366 13.4114L14.6052 17.08Z" />
	</SvgIcon>
));

IconDownload.displayName = 'IconDownload';

export default IconDownload;
