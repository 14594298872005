import { defineMessages } from 'gatsby-theme-fast-ai-sidebar';

export default defineMessages({
	home: {
		id: 'route.home',
		defaultMessage: '/',
	},
	scheduleDemo: {
		id: 'route.scheduleDemo',
		defaultMessage: '/schedule-demo',
	},
	careers: {
		id: 'route.careers',
		defaultMessage: '/careers',
	},
	contacts: {
		id: 'route.contacts',
		defaultMessage: '/schedule-demo#contacts',
	},
	blog: {
		id: 'route.blog',
		defaultMessage: '/blog',
	},
	references: {
		id: 'route.references',
		defaultMessage: '/references',
	},
	dr: {
		id: 'route.dr',
		defaultMessage: '/deep-recommendation',
	},
	baas: {
		id: 'route.baas',
		defaultMessage: '/baas',
	},
	downloadEbook: {
		id: 'route.downloadEbook',
		defaultMessage: '/download-ebook',
	},
});
