/* eslint-disable max-len */
import React, { Fragment } from 'react';

import createSvgIcon from './utils/createSvgIcon';

export default createSvgIcon(
	<Fragment>
		<g
			fill="none"
			stroke="currentColor"
			strokeMiterlimit="10"
			strokeWidth="2"
			data-name="Layer 2"
		>
			<path d="M2.74 23H11v-4l-3-1 1-4 7-3-2-4H4.02" />
			<circle cx="16" cy="16" r="15" />
			<path d="M25 9h-2M21 9h-2M30.95 15H28l-3 4h-3l-.86 3H17v4h10.16" />
		</g>
	</Fragment>,
	32,
	'Programming3Asset15'
);
