import React, {
	forwardRef,
	useCallback,
	useEffect,
	useRef,
	useState,
} from 'react';
import PropTypes from 'prop-types';
import { createPopper } from '@popperjs/core';
import { useBlockingEffect } from '@fast-ai/ui-components';

import Portal from '../Portal';
import setRef from '../utils/setRef';
import useForkRef from '../utils/useForkRef';

const getAnchorEl = anchorEl =>
	typeof anchorEl === 'function' ? anchorEl() : anchorEl;

const defaultPopperOptions = {};

/**
 * Poppers rely on the 3rd party library
 * [Popper.js](https://popper.js.org/docs/v2/) for positioning.
 *
 * Fork of:
 * @see https://github.com/mui-org/material-ui/blob/next/packages/material-ui/src/Popper/Popper.js
 */
const Popper = forwardRef(
	(
		{
			anchorEl,
			children,
			container,
			disablePortal = false,
			keepMounted = false,
			modifiers,
			isOpened,
			placement: initialPlacement = 'bottom',
			popperOptions = defaultPopperOptions,
			popperRef: popperRefProp,
			style,
			transition = false,
			...other
		},
		ref
	) => {
		const tooltipRef = useRef(null);
		const ownRef = useForkRef(tooltipRef, ref);

		const popperRef = useRef(null);
		const handlePopperRef = useForkRef(popperRef, popperRefProp);
		const handlePopperRefRef = useRef(handlePopperRef);

		useBlockingEffect(() => {
			handlePopperRefRef.current = handlePopperRef;
		}, [handlePopperRef]);
		React.useImperativeHandle(popperRefProp, () => popperRef.current, []);

		const [exited, setExited] = React.useState(true);

		const rtlPlacement = initialPlacement;
		/*
		 * placement initialized from prop but can change during lifetime
		 * if modifiers.flip.
		 * modifiers.flip is essentially a flip for controlled/uncontrolled behavior
		 */
		const [placement, setPlacement] = useState(rtlPlacement);

		React.useEffect(() => {
			if (popperRef.current) {
				popperRef.current.forceUpdate();
			}
		});

		const handleOpen = useCallback(() => {
			if (!tooltipRef.current || !anchorEl || !isOpened) {
				return;
			}

			if (popperRef.current) {
				popperRef.current.destroy();
				handlePopperRefRef.current(null);
			}

			const handlePopperUpdate = data => {
				setPlacement(data.placement);
			};

			const resolvedAnchorEl = getAnchorEl(anchorEl);

			if (process.env.NODE_ENV !== 'production') {
				if (resolvedAnchorEl && resolvedAnchorEl.nodeType === 1) {
					const box = resolvedAnchorEl.getBoundingClientRect();

					if (
						process.env.NODE_ENV !== 'test' &&
						box.top === 0 &&
						box.left === 0 &&
						box.right === 0 &&
						box.bottom === 0
					) {
						// eslint-disable-next-line no-console
						console.warn(
							[
								'The `anchorEl` prop provided to the component is invalid.',
								'The anchor element should be part of the document layout.',
								"Make sure the element is present in the document or that it's not display none.",
							].join('\n')
						);
					}
				}
			}

			let popperModifiers = [
				{
					name: 'preventOverflow',
					options: {
						altBoundary: disablePortal,
					},
				},
				{
					name: 'flip',
					options: {
						altBoundary: disablePortal,
					},
				},
				{
					name: 'onUpdate',
					enabled: true,
					phase: 'afterWrite',
					fn({ state }) {
						handlePopperUpdate(state);
					},
				},
			];

			if (modifiers != null) {
				popperModifiers = popperModifiers.concat(modifiers);
			}
			if (popperOptions && popperOptions.modifiers != null) {
				popperModifiers = popperModifiers.concat(popperOptions.modifiers);
			}

			const popper = createPopper(getAnchorEl(anchorEl), tooltipRef.current, {
				placement: rtlPlacement,
				...popperOptions,
				modifiers: popperModifiers,
			});

			handlePopperRefRef.current(popper);
		}, [
			anchorEl,
			disablePortal,
			modifiers,
			isOpened,
			rtlPlacement,
			popperOptions,
		]);

		const handleRef = useCallback(
			node => {
				setRef(ownRef, node);
				handleOpen();
			},
			[ownRef, handleOpen]
		);

		const handleEnter = () => {
			setExited(false);
		};

		const handleClose = () => {
			if (!popperRef.current) {
				return;
			}

			popperRef.current.destroy();
			handlePopperRefRef.current(null);
		};

		const handleExited = () => {
			setExited(true);
			handleClose();
		};

		useEffect(
			() => () => {
				handleClose();
			},
			[]
		);

		useEffect(() => {
			if (!isOpened && !transition) {
				// Otherwise handleExited will call this.
				handleClose();
			}
		}, [isOpened, transition]);

		if (!keepMounted && !isOpened && (!transition || exited)) {
			return null;
		}

		const childProps = { placement };

		if (transition) {
			childProps.TransitionProps = {
				in: isOpened,
				onEnter: handleEnter,
				onExited: handleExited,
			};
		}

		return (
			<Portal disablePortal={disablePortal} container={container}>
				<div
					ref={handleRef}
					role="tooltip"
					{...other}
					style={{
						// Prevents scroll issue, waiting for Popper.js
						// to add this style once initiated.
						position: 'fixed',
						// Fix Popper.js display issue
						top: 0,
						left: 0,
						display: !isOpened && keepMounted && !transition ? 'none' : null,
						...style,
					}}
				>
					{typeof children === 'function' ? children(childProps) : children}
				</div>
			</Portal>
		);
	}
);

Popper.propTypes = {
	/**
	 * A HTML element, [virtualElement](https://popper.js.org/docs/v2/virtual-elements/),
	 * or a function that returns either.
	 * It's used to set the position of the popper.
	 * The return value will passed
	 * as the reference object of the Popper instance.
	 */
	anchorEl: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
	/**
	 * Popper render function or node.
	 */
	children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
	/**
	 * A HTML element or function that returns one.
	 * The `container` will have the portal children appended to it.
	 *
	 * By default, it uses the body of the top-level document object,
	 * so it's simply `document.body` most of the time.
	 */
	container: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
	/**
	 * The `children` will be inside the DOM hierarchy of the parent component.
	 * @default false
	 */
	disablePortal: PropTypes.bool,
	/**
	 * If `true`, the popper is visible.
	 */
	isOpened: PropTypes.bool.isRequired,
	/**
	 * Always keep the children in the DOM.
	 * This prop can be useful in SEO situation or
	 * when you want to maximize the responsiveness of the Popper.
	 * @default false
	 */
	keepMounted: PropTypes.bool,
	/**
	 * Popper.js is based on a "plugin-like" architecture,
	 * most of its features are fully encapsulated "modifiers".
	 *
	 * A modifier is a function that is called each time Popper.js needs to
	 * compute the position of the popper.
	 * For this reason, modifiers should be very performant to avoid bottlenecks.
	 * To learn how to create a modifier, [read the modifiers documentation](https://popper.js.org/docs/v2/modifiers/).
	 */
	modifiers: PropTypes.arrayOf(
		PropTypes.shape({
			data: PropTypes.object,
			effect: PropTypes.func,
			enabled: PropTypes.bool,
			fn: PropTypes.func,
			name: PropTypes.any.isRequired,
			options: PropTypes.object,
			phase: PropTypes.oneOf([
				'afterMain',
				'afterRead',
				'afterWrite',
				'beforeMain',
				'beforeRead',
				'beforeWrite',
				'main',
				'read',
				'write',
			]),
			requires: PropTypes.arrayOf(PropTypes.string),
			requiresIfExists: PropTypes.arrayOf(PropTypes.string),
		})
	),
	/**
	 * Popper placement.
	 * @default 'bottom'
	 */
	placement: PropTypes.oneOf([
		'auto-end',
		'auto-start',
		'auto',
		'bottom-end',
		'bottom-start',
		'bottom',
		'left-end',
		'left-start',
		'left',
		'right-end',
		'right-start',
		'right',
		'top-end',
		'top-start',
		'top',
	]),
	/**
	 * Options provided to the [`Popper.js`](https://popper.js.org/docs/v2/constructors/#options) instance.
	 * @default {}
	 */
	popperOptions: PropTypes.shape({
		modifiers: PropTypes.array,
		onFirstUpdate: PropTypes.func,
		placement: PropTypes.oneOf([
			'auto-end',
			'auto-start',
			'auto',
			'bottom-end',
			'bottom-start',
			'bottom',
			'left-end',
			'left-start',
			'left',
			'right-end',
			'right-start',
			'right',
			'top-end',
			'top-start',
			'top',
		]),
		strategy: PropTypes.oneOf(['absolute', 'fixed']),
	}),
	/**
	 * A ref that points to the used popper instance.
	 */
	popperRef: PropTypes.object,
	/**
	 * @ignore
	 */
	style: PropTypes.object,
	/**
	 * Help supporting a react-transition-group/Transition component.
	 * @default false
	 */
	transition: PropTypes.bool,
};

export default Popper;
