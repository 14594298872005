/* eslint-disable react/prop-types,react/display-name */
import React, { Fragment } from 'react';
import { Box, Col, Heading, Row } from '@fast-ai/ui-components';
import { Paragraph } from 'gatsby-theme-fast-ai-sidebar/src/mdxComponents';
import IconPin from '@fast-ai/dashboard-icons/src/LocationAndMap1Asset23';
import PhoneIcon from '@fast-ai/dashboard-icons/src/BusinessAndFinance1Asset3';
import useEventCallback from '@fast-ai/ui/utils/useEventCallback';
import { keyframes } from '@emotion/core';

import { BlockSpan } from '../ui';
import c from '../intl/messages/contacts';
import { useTranslateMessage } from '../intl';

const phoneAnimation = keyframes`
0% { transform: rotate(0); }
  1% { transform: rotate(30deg); }
  3% { transform: rotate(-28deg); }
  5% { transform: rotate(34deg); }
  7% { transform: rotate(-32deg); }
  9% { transform: rotate(30deg); }
  11% { transform: rotate(-28deg); }
  13% { transform: rotate(26deg); }
  15% { transform: rotate(-24deg); }
  17% { transform: rotate(22deg); }
  19% { transform: rotate(-20deg); }
  21% { transform: rotate(18deg); }
  23% { transform: rotate(-16deg); }
  25% { transform: rotate(14deg); }
  27% { transform: rotate(-12deg); }
  29% { transform: rotate(10deg); }
  31% { transform: rotate(-8deg); }
  33% { transform: rotate(6deg); }
  35% { transform: rotate(-4deg); }
  37% { transform: rotate(2deg); }
  39% { transform: rotate(-1deg); }
  41% { transform: rotate(1deg); }

  43% { transform: rotate(0); }
  100% { transform: rotate(0); }
`;
const contacts = [
	{
		name: 'Aguan s.r.o.',
		city: c.cityPrague,
		state: c.czechRepublic,
		lines: ['Kaprova 42/14', '110 00 Praha'],
		phoneNumber: '+420 222 253 015',
		email: 'info@lundegaard.eu',
		companyIN: '24173681',
	},
	{
		name: 'Lundegaard a.s.',
		city: c.cityPrague,
		state: c.czechRepublic,
		lines: [
			'Futurama Business Park',
			'Sokolovská 651/136a',
			'186 00 Praha 8 - Karlín',
		],
		companyIN: '25687221',
		phoneNumber: '+420 222 253 015',
		email: 'info@lundegaard.eu',
	},
	{
		name: 'Lundegaard a.s.',
		city: 'Brno',
		state: c.czechRepublic,
		lines: [
			'Ponávka Business Centre',
			'Škrobárenská 502/1',
			'617 00 Brno - jih',
		],
		companyIN: '25687221',
		phoneNumber: '+420 222 253 025',
		email: 'info@lundegaard.eu',
	},
	{
		name: 'Lundegaard a.s.',
		city: 'Hradec Králové',
		state: c.czechRepublic,
		lines: ['Velké náměstí 1/3', '500 03 Hradec Králové'],
		companyIN: '25687221',
		phoneNumber: '+420 222 253 015',
		email: 'office.hradec-kralove@lundegaard.eu',
	},
];
const PhoneRinging = () => {
	const setPath = useEventCallback(node => {
		if (!node) {
			return;
		}
		const path = node.getElementsByTagName('path')?.[0];
		const g = node.getElementsByTagName('g')?.[0];
		const BBox = g.getBBox();
		path.style.transformOrigin = `${BBox.x + BBox.height / 2}px ${
			BBox.y + BBox.width / 2
		}px`;
	});

	return (
		<Box
			ref={setPath}
			sx={{
				py: 4,
				color: 'blue',
				svg: {
					overflow: 'visible',
					'path:first-of-type': {
						animationName: phoneAnimation,
						animationDuration: '4s',
						animationDelay: '0.zs',
						animationIterationCount: 'infinite',
						// animationFillMode: 'both',
						animationTimingFunction: 'ease-in-out',
						// animationDirection: 'alternate',
						stroke: 'secondary',
					},
				},
			}}
		>
			<PhoneIcon size={128} />
		</Box>
	);
};
const Contacts = ({ sx, ...rest }) => {
	const translate = useTranslateMessage();
	return (
		<Fragment>
			<Row
				sx={{
					flexWrap: 'wrap',
					textAlign: 'center',
					alignItems: 'center',
					justifyContent: 'center',
					...sx,
				}}
				{...rest}
			>
				<PhoneRinging />
			</Row>
			<Row sx={{ flexWrap: 'wrap' }}>
				{contacts.map(
					({ city, state, lines, email, phoneNumber, name, companyIN }, i) => (
						<Col
							key={i}
							span={[12, 6, 3]}
							sx={{ mb: [3, 0, 0], textAlign: ['left', 'left', 'left'] }}
						>
							<Heading
								as="h2"
								variant="subHeading1"
								sx={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: ['flex-start', 'flex-start', 'flex-start'],
								}}
							>
								<Box as={IconPin} sx={{ mr: 2, color: 'primary' }} size={32} />
								{translate(city)}
							</Heading>
							<Paragraph>
								{name ? (
									<BlockSpan sx={{ fontWeight: 'bold' }}>{name}</BlockSpan>
								) : null}
								{lines.map(line => (
									<BlockSpan key={line}>{line}</BlockSpan>
								))}
								<BlockSpan>{translate(state)}</BlockSpan>
								{companyIN ? (
									<BlockSpan>
										{translate([c.companyIN, { companyIN }])}
									</BlockSpan>
								) : null}
								<BlockSpan sx={{ mt: 2 }}>
									<a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
								</BlockSpan>
								<BlockSpan>
									<a href={`mailto:${email}`}>{email}</a>
								</BlockSpan>
							</Paragraph>
						</Col>
					)
				)}
			</Row>
		</Fragment>
	);
};
export default Contacts;
