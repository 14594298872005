import useJustIsInViewport from '@fast-ai/ui/utils/useJustIsInViewport';
import { keyframes } from '@emotion/core';

const moveInAnimation = keyframes`
0% { transform: scale(1,1); }
2% { transform: scale(1.15,1.15); }
5% { transform: scale(1.05,1.05); }
10% { transform: scale(1.25,1.25); }
15% { transform: scale(1.1,1.1); }
20% { transform: scale(1.2,1.2); }
25% { transform: scale(1.1,1.1); }
30% { transform: scale(1.15,1.15); }
35% { transform: scale(1.1,1.1); }
40% { transform: scale(1.12,1.12); }
45% { transform: scale(1.1,1.1); }
50% { transform: scale(1,1); }
55% { transform: scale(1.05,1.05); }
60% { transform: scale(1,1); }
`;
const FocusIt = ({ children, sx, duration = '4s', ...rest }) => {
	const { ref, shown } = useJustIsInViewport();

	return children({
		ref,
		sx: {
			transformOrigin: '50% 50%',
			willChange: 'transform',
			transform: 'scale(1,1)',
			animationName: shown ? moveInAnimation : null,
			animationDuration: duration,
			animationIterationCount: 'infinite',
			animationFillMode: 'both',
			animationTimingFunction: 'ease-in',
			...sx,
		},
		...rest,
	});
};
export default FocusIt;
